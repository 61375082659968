import Header from '~/components/header/header'
import Dashboard from '~/components/dashboard/dashboard'

export default function Home() {
  return (
    <>
      <Header />
      <Dashboard />
    </>
  )
}
