import { reportError } from '~/services/errors'
import { getUserToken } from '~/services/supabase'
import { RecentCollabsResponse } from '~/types/database/collaborators'

const getRecentCollabs = async () => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/collaborator/recent', {
      method: 'GET',
      headers: {
        'X-SUPABASE-TOKEN': token
      }
    })
    const result: RecentCollabsResponse = await data.json()
    if (result.error) {
      const error = new Error(result.error.message)
      reportError(error)
    }
    return result
  }
}

export {
  getRecentCollabs
}