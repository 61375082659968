import { Component } from 'solid-js'
import { WorksChapterEntryProps } from '~/components/works-chapter-entry/works-chapter-entry.interfaces'
import styles from '~/components/works-chapter-entry/works-chapter-entry.module.scss'
import ClockIcon from '~/assets/icons/clock.svg'
import NewPageIcon from '~/assets/icons/external-link.svg'
import ChapterProgression from '~/components/chapter-progression/chapter-progression'
import { getFullDate } from '~/helpers/dates'
import { A, useNavigate } from '@solidjs/router'
import { getChapterEditorLink, getSeriesLink } from '~/services/link'
import { user } from '~/services/database/users'
import { getSeries } from '~/services/database/series'
import { translations } from '~/translations'

const WorksChapterEntry: Component<WorksChapterEntryProps> = ({ entry }) => {
  const navigate = useNavigate()
  const updatedAt = getFullDate(new Date(entry.updated_at))

  const onClick = async () => {
    const userId = user()?.id
    const series = await getSeries(entry.series_id)
    const userPermission = series?.series_permissions.find(permissions => permissions.user_id === userId)
    const useCanWorkOnCurrentStep = ((userPermission?.permission === entry.progress) && !userPermission.revoked) || userPermission?.permission === 'admin'
    if(useCanWorkOnCurrentStep){
      navigate(getChapterEditorLink(entry))
    }else{
      navigate(getSeriesLink(entry.series_id, entry.id))
    }
  }

  const stopPropagation = (event: MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <button class={styles.worksChapterEntry} onClick={onClick}>
      <div class={styles.series}>
        <div class={styles.client}>
          {entry.series?.client}
        </div>
        <A
          class={styles.name}
          href={getSeriesLink(entry.series_id)}
          onClick={stopPropagation}
        >
          <span>{entry.series?.title}</span>
          <NewPageIcon class={styles.icon} />
        </A>
      </div>
      <div class={styles.chapter}>
        <div class={styles.index}>
          {translations().dashboard.wip.chapter} {entry.index}
        </div>
        {entry.title && (
          <div class={styles.name}>
            {entry.title}
          </div>
        )}
      </div>
      <ChapterProgression entry={entry} />
      <div class={styles.lastUpdate} title={translations().dashboard.wip.lastUpdate}>
        <ClockIcon class={styles.icon} /> {updatedAt}
      </div>
    </button>
  )
}

export default WorksChapterEntry
