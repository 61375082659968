import { Component } from 'solid-js'
import styles from '~/components/dashboard-discord/dashboard-discord.module.scss'
import DiscordIcon from '~/assets/icons/discord.svg'
import { translations } from '~/translations'

const DashboardDiscord: Component = () => {
  return (
    <a
      class={styles.dashboardDiscord}
      href='https://discord.gg/8ZyTppHWKk'
      target='blank'
    >
      <div class={styles.title}>
        <div class={styles.minor}>
          {translations().dashboard.discord.title1}
        </div>
        <div class={styles.major}>
          {translations().dashboard.discord.title2}
        </div>
      </div>
      <div></div>
      <DiscordIcon class={styles.icon} />
    </a>
  )
}

export default DashboardDiscord
