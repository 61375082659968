import { Component } from 'solid-js'
import AccountPartnersAddForm from '~/components/account-partners-add/account-partners-add-form'
import { AddPartnerModalProps } from '~/components/add-partner-modal/add-partner-modal.interfaces'
import Modal from '~/components/modal/modal'
import { translations } from '~/translations'

const AddPartnerModal: Component<AddPartnerModalProps> = ({ contacts, onSuccess, open, setOpen }) => {
  const onContactAdd = () => {
    setOpen(false)
    onSuccess()
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={translations().dashboard.contacts.invite.title}
    >
      <AccountPartnersAddForm
        contacts={contacts}
        onSuccess={onContactAdd}
      />
    </Modal>
  )
}

export default AddPartnerModal
