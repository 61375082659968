import { Component } from 'solid-js'
import styles from '~/components/dashboard-works/dashboard-works.module.scss'
import WorksChaptersList from '~/components/works-chapters-list/works-chapters-list'

const DashboardWorks: Component = () => {
  return (
    <div class={styles.dashboardWorks}>
      <img src='/assets/images/illustration-toons.png' class={styles.illustration} />
      <WorksChaptersList />
    </div>
  )
}

export default DashboardWorks
