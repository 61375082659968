import { Component, For, createResource } from 'solid-js'
import EmptyState from '~/components/empty-state/empty-state'
import WorksChapterEntry from '~/components/works-chapter-entry/works-chapter-entry'
import styles from '~/components/works-chapters-list/works-chapters-list.module.scss'
import { chaptersInProgress } from '~/services/works-in-progress/chapters'
import EmptyIcon from '~/assets/icons/document-empty.svg'
import { translations } from '~/translations'

const WorksChaptersList: Component = () => {
  const [chapters] = createResource(chaptersInProgress)

  return (
    <div class={styles.worksChaptersList}>
      <div class={styles.title}>
        {translations().dashboard.wip.title}
      </div>
      <div class={styles.tableHead}>
        <div class={styles.column}>
          {translations().dashboard.wip.series}
        </div>
        <div class={styles.column}>
          {translations().dashboard.wip.chapter}
        </div>
        <div class={styles.column}>
          {translations().dashboard.wip.progress}
        </div>
        <div class={styles.column}>
          {translations().dashboard.wip.lastUpdate}
        </div>
      </div>
      <div class={styles.list}>
        <For each={chapters.latest} fallback={(
          <EmptyState
            icon={EmptyIcon}
            label={translations().dashboard.wip.emptyState.title}
            description={translations().dashboard.wip.emptyState.description}
          />
        )}>
          {entry => (
            <WorksChapterEntry entry={entry} />
          )}
        </For>
      </div>
    </div>
  )
}

export default WorksChaptersList
