import { Component, For, Show, createResource, createSignal } from 'solid-js'
import styles from '~/components/dashboard-collaborators/dashboard-collaborators.module.scss'
import FavoriteIcon from '~/assets/icons/favorite.svg'
import RecentIcon from '~/assets/icons/clock.svg'
import CollaboratorEntry from '~/components/collaborator-entry/collaborator-entry'
import { createContact, deleteContact, getContacts } from '~/services/database/contacts'
import { UserEntry } from '~/components/dashboard-collaborators/dashboard-collaborators.interfaces'
import { Role } from '~/services/database/permissions'
import { getRecentCollabs } from '~/services/database/collabs'
import AddPartnerModal from '~/components/add-partner-modal/add-partner-modal'
import SmallButton from '~/components/small-button/small-button'
import { translations } from '~/translations'

const DashboardCollaborators: Component = () => {
  const [showPartnerAdd, setShowPartnerAdd] = createSignal(false)
  const [contacts, { refetch: refetchContacts }] = createResource(getContacts)
  const [collabs, { refetch: refetchRecentCollabs }] = createResource(getRecentCollabs)

  // TODO: Add alpha sort and a toggle button in the UI
  const sortBy = (contacts: UserEntry[] = []) => {
    return contacts.sort((a, b) => {
      const aValue = new Role(a.meta?.preferred_role).index
      const bValue = new Role(b.meta?.preferred_role).index
      return aValue - bValue
    })
  }

  const showAddPartnerModal = () => {
    setShowPartnerAdd(true)
  }

  const onContactDelete = (contactId: string) => async () => {
    await deleteContact({ contactId })
    onContactUpdate()
  }

  const onContactAdd = (contactId: string) => async () => {
    await createContact({ contactId })
    onContactUpdate()
  }

  const onContactUpdate = () => {
    refetchContacts()
    refetchRecentCollabs()
  }

  const recentCollabs = () => {
    const contactIds = contacts.latest?.map(entry => entry.contact_id) ?? []
    const nonContactCollabs = (collabs.latest?.collaborators ?? []).filter(entry => !contactIds.includes(entry.userId))
    return nonContactCollabs
  } 

  return (
    <div class={styles.dashboardCollaborators}>
      <AddPartnerModal
        contacts={contacts.latest ?? []}
        open={showPartnerAdd()}
        setOpen={setShowPartnerAdd}
        onSuccess={onContactUpdate}
      />

      <img src='/assets/images/illustration-collaborators.png' class={styles.illustration} />

      <div class={styles.title}>
        {translations().dashboard.contacts.title}
      </div>

      <div class={styles.collaboratorsList}>
        <div class={styles.sectionTitle}>
          <FavoriteIcon class={styles.icon} />
          <div class={styles.text}>
            <div class={styles.minor}>
              {translations().dashboard.contacts.savedContacts.title1}
            </div>
            <div class={styles.major}>
              {translations().dashboard.contacts.savedContacts.title2}
            </div>
          </div>
          <SmallButton
            light
            onClick={showAddPartnerModal}
          >
            {translations().general.actions.add}
          </SmallButton>
        </div>

        <div class={styles.list}>
          <For each={sortBy(contacts.latest)} fallback={(
            <div class={styles.emptyFavorites}>
              {translations().dashboard.contacts.savedContacts.emptyState}
            </div>
          )}>
            {entry => (
              <CollaboratorEntry
                user={entry.meta}
                actionLabel={translations().dashboard.contacts.savedContacts.remove}
                onClick={onContactDelete(entry.contact_id)}
              />
            )}
          </For>
        </div>
      </div>

      <Show when={collabs.latest?.collaborators?.length}>
        <div class={styles.collaboratorsList}>
          <div class={styles.sectionTitle}>
            <RecentIcon class={styles.icon} />
            <div class={styles.text}>
              <div class={styles.minor}>
                {translations().dashboard.contacts.recentContacts.title1}
              </div>
              <div class={styles.major}>
                {translations().dashboard.contacts.recentContacts.title2}
              </div>
            </div>
          </div>

            <div class={styles.list}>
              <For each={recentCollabs()}>
                {entry => (
                  <CollaboratorEntry
                    user={entry}
                    actionLabel={translations().dashboard.contacts.recentContacts.add}
                    onClick={onContactAdd(entry.userId)}
                  />
                )}
              </For>
            </div>
        </div>
      </Show>
    </div>
  )
}

export default DashboardCollaborators
