import { Component } from 'solid-js'
import styles from '~/components/dashboard-user/dashboard-user.module.scss'
import { user } from '~/services/database/users'
import { translations } from '~/translations'
import { roles } from '~/translations/account-roles'

const DashboardUser: Component = () => {
  const role = () => user()?.preferred_role ?? 'admin'
  const roleId = () => roles.find(entry => entry.value === role())?.value ?? 'admin'
  const roleIllustration = () => `/assets/images/illustration-${role()}.svg`
  const cssClasses = () => [styles.dashboardUser, styles[role()]].join(' ')

  return (
    <div class={cssClasses()}>
      <div class={styles.welcome}>
        <div class={styles.minor}>
          {translations().dashboard.user.welcome}
        </div>
        <div class={styles.major}>
          {user()?.name}
        </div>
      </div>
      <div class={styles.job}>
        <div class={styles.minor}>
          {translations().dashboard.user.currentRole}
        </div>
        <div class={styles.major}>
          {translations().user.role[roleId()]}
        </div>
      </div>
      <img
        class={styles.illustration}
        src={roleIllustration()}
      />
    </div>
  )
}

export default DashboardUser
