import { WorksChapterEntryProps } from '~/components/works-chapter-entry/works-chapter-entry.interfaces'
import { filterDeletedEntries } from '~/helpers/database-conversions'
import { reportError } from '~/services/errors'
import { supabase } from '~/services/supabase'

const chaptersInProgress = async () => {
  const { data: chapters, error } = await supabase
    .from('chapters')
    .select('*, series(*), deleted:chapters_deletions(count)')
    .returns<WorksChapterEntryProps['entry'][]>()
    
  if (error) {
    reportError(error)
  }

  const filteredByNotDeleted = chapters ? filterDeletedEntries(chapters) : []
  const sortedByDate = filteredByNotDeleted.sort((a, b) => {
    return new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf()
  })
  return sortedByDate
}

export {
  chaptersInProgress
}